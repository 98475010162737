.App-body {
  min-height: 100vh;
  max-width: 80vh;
  flex-direction: column;
  color: black;
  padding: 20px
}

h2 {
  text-align: justify;
  margin-top: 50px;
}

h3 {
  text-align: justify;
  margin-top: 0px;
}

p {
  text-align: justify;
  font-size: calc(18px);
  line-height: 1.5;
  color: rgba(41, 36, 36, 0.994);
}

li {
  font-size: calc(18px);
  line-height: 1.5;
  color: rgba(41, 36, 36, 0.994);
}

.App-warning {
  text-align: right;
  color: rgba(211, 11, 11, 0.733);
}